import localConfig from '@/config';
import { QueryKeys } from '@/constants/queryKeys';
import { contentfulClient } from '@/utils';
import { useQuery } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import { FormattedSuggestion, SuggestionsSkeleton } from '../types';

interface TransformedStructure {
  ask: FormattedSuggestion;
  askActions: FormattedSuggestion;
  deploy: FormattedSuggestion;
  deployActions: FormattedSuggestion;
  transact: FormattedSuggestion;
  transactActions: FormattedSuggestion;
  chatWindow: FormattedSuggestion;
  dashboardWidget: FormattedSuggestion;
}

const fetchSuggestions = async () => {
  try {
    let content = [];

    if (localConfig.showContentLive) {
      content = (
        await contentfulClient.getEntries<SuggestionsSkeleton>({
          content_type: 'suggestions',
          include: 10,
        })
      ).items;
    } else {
      content = (
        await axios.get<void, AxiosResponse<SuggestionsSkeleton[]>>(`${localConfig.contentfulUrl}/suggestions.json`)
      ).data;
    }

    const result: TransformedStructure = {
      ask: { suggestions: [] },
      askActions: { suggestions: [] },
      deploy: { suggestions: [] },
      deployActions: { suggestions: [] },
      transact: { suggestions: [] },
      transactActions: { suggestions: [] },
      chatWindow: { suggestions: [] },
      dashboardWidget: { suggestions: [] },
    };

    content.forEach((entry) => {
      const { name, suggestions } = entry.fields;

      const resultSuggestions = (suggestions || []).map((item) => ({
        label: item.fields.label,
        value: item.fields.value,
      }));

      switch (name) {
        case 'Ask Main Suggestions':
          result.ask.suggestions = resultSuggestions;
          break;
        case 'Ask Actions':
          result.askActions.suggestions = resultSuggestions;
          break;
        case 'Deploy Main Suggestions':
          result.deploy.suggestions = resultSuggestions;
          break;
        case 'Deploy Actions':
          result.deployActions.suggestions = resultSuggestions;
          break;
        case 'Transact Main Suggestions':
          result.transact.suggestions = resultSuggestions;
          break;
        case 'Transact Actions':
          result.transactActions.suggestions = resultSuggestions;
          break;
        case 'Chat Window Suggestions':
          result.chatWindow.suggestions = resultSuggestions;
          break;
        case 'Dashboard widget':
          result.dashboardWidget.suggestions = resultSuggestions;
          break;
        default:
          break;
      }
    });

    return result;
  } catch (e) {
    console.error(e);
    return;
  }
};

export const useSuggestions = (options = {}) => {
  return useQuery({
    queryKey: [QueryKeys.AI_TERMINAL_SUGGESTIONS],
    queryFn: fetchSuggestions,
    ...options,
  });
};
