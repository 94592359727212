import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgArrowRightIcon = (
  { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    ref={ref}
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.53255 3.12622L14.4063 7.99998L9.53255 12.8738L8.82545 12.1667L12.4921 8.49998H2.19922V7.49998H12.4921L8.82545 3.83333L9.53255 3.12622Z"
      fill="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgArrowRightIcon);
export default ForwardRef;
